<template>
  <div class="bg" :style="{backgroundImage:'url('+bg3+')'}">
    <div class="header">
      <div class="logo-box">
        <div class="logo" @click="showModel()">
          <img v-if="companyLogo" :src="companyLogo" alt="">
          <div v-else class="logo-title">{{companyName}}</div>
        </div>
        <div class="interval dingwei">
        </div>
      </div>
      <div class="title">
        {{ mationInfo.salaryScreenTitle }}
      </div>
      <div class="date-box" >
        <div class="left">
          <div class="time">{{moment(dataTime).format('HH:mm:ss')}}</div>
          <!-- <span class="span-division ">|</span> -->
          <div class="date">{{moment(dataTime).format('YYYY年MM月DD日')}}</div>
        </div>
        <div class="tab-project">
          <div @click="showOpenListFn()" class="more cursor-pointer">
            <img  src="@/assets/screen/more.png" alt="">
          </div>
          <!-- <div class="tab-list" v-if="showOpenList">
            <div class="select-tab cursor-pointer" v-if="SwitchInfo.companyScreenPermissionStatus == 1" @click="open(1)">
              {{SwitchInfo.companyScreenTitle}}
            </div>
            <div class="select-tab cursor-pointer" v-if="SwitchInfo.projectScreenPermissionStatus == 1"  @click="open(2)">
              {{SwitchInfo.projectScreenTitle}}
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="content" @dblclick="shopScroll" v-if="dataList.length>0">
      <div v-if="start.team > 3">
        <vue-seamless-scroll 
        :direction="direction"
        :data="dataList" 
        :class-option="defaultOption" >
          <div  v-for="(item,index) in dataList" :key="index" >
          <tableBox  @tableShow="popUp" class="mb-20 " :dataInfo="item">
            </tableBox>
          </div>
        </vue-seamless-scroll>
      </div>
      <div v-else-if="start.team < 3 && start.list > 9">
      <vue-seamless-scroll 
          :direction="direction"
          :data="dataList" 
          :class-option="defaultOption" >
            <div  v-for="(item,index) in dataList" :key="index" >
            <tableBox  @tableShow="popUp" class="mb-20 " :dataInfo="item">
              </tableBox>
            </div>
          </vue-seamless-scroll>
      </div>
      <div v-else>
        <div  v-for="(item,index) in dataList" :key="index" >
        <tableBox  @tableShow="popUp" class="mb-20 " :dataInfo="item">
          </tableBox>
        </div>
      </div>
    </div>
    <div v-else class="no-top-img">
      <img src="../../assets/screen/noData.png" alt="">
    </div>
    <div class='model-shadow' v-if='visible'>
      <div class="model">
        <div class="title">薪资详情</div>
        <div class="close hand" @click="close()">×</div>
        <div class="model-box">
          <div class="model-content">
            <div class="table-title border">
              <div class="table-multiple rb">
                薪资
              </div>
              <div class="table-smultiple ">
                人数
              </div>
          </div>
            <div class="model-scroll">
              <div class="table-td borde" v-for="(item,index) in modelObj" :key="index">
                <div class="table-single rb ">
                  {{item.salary}}
                </div>
                <div class="table-single  ">
                  {{item.userSectionNum}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class='model-shadow2' v-if='visible2'>
      <div class="model">
        <div class="title">备注原因</div>
        <div class="close hand" @click="close2()">×</div>
        <div class="model-box">
          <div class="model-content">
            <div class="table-title border">
              <div class="table-single rb">
                姓名
              </div>
              <div class="table-single rb">
                工种
              </div>
              <div class="table-multiple yuanyin ">
                原因
              </div>
          </div>
          <div class="model-scroll">
            <div class="table-td borde" v-for="(item,index) in modelObj2" :key="index">
              <div class="table-single rb ">
                {{item.userName}}
              </div>
              <div class="table-single  rb ">
                {{item.typeWorkName}}
              </div>
              <div class="table-multiple ">
                {{item.reason}}
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>

    <div class='model-shadow' v-if='showOpenList'>
      <div class="models">
        <div class="models-box"  v-if="!showNull">
          <div class="modelcontent cursor-pointer"  @click="open(1)" v-if="SwitchInfo.companyScreenPermissionStatus == 1">
            <div class="modeltext"  >
              {{SwitchInfo.companyScreenTitle}}
            </div>
          </div>
          <div class="modelcontent cursor-pointer" @click="open(2)" v-if="SwitchInfo.projectScreenPermissionStatus == 1">
            <div class="modeltext"   >
              <!-- {{SwitchInfo.projectScreenTitle}} -->
              数字化劳动成果大屏
            </div>
          </div>
          <div class="close cursor-pointer" @click="closeTab">
          </div>
        </div>
        <div class="models-box" v-if="showNull">
          <div class="zwqx">暂无权限</div>
          <div class="close cursor-pointer" @click="closeTab">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import moment from "moment"
import tableBox from "./component/table.vue"
import vueSeamlessScroll from 'vue-seamless-scroll'  // vue2引入方式

import { cloneDeep } from 'lodash'
 import { 
  codeLoselectSalaryScreengin,
  currCompanyShowDetailInfo,
  screenSwitchInfo
 } from "../../services/salary.js";
export default {
  name:"home",
  components:{
    tableBox,
    vueSeamlessScroll
  },
  data(){
    return{
      Vue,
      shopType:true,
      moment,
      title:require("@/assets/screen/title.png"),
      bg3:require("@/assets/screen/bg3.png"),
      goback:require('@/assets/screen/goBack1.png'),
      dataTime:"2021-05-01 00:00:00",
      tablePage:true,
      // 用户信息
      companyName:"", //企业名称
      companyLogo:"",//企业logo
      engineeringId: null, // 工程id
      dataList:[],
			visible:false,
			visible2:false,
      modelObj:[],
      modelObj2:[],
      defaultOption:{
        step:0.5, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: false, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      },

      copyList:[],
      scrollType:false,
      // autoPlay:true,
      start:{
          company:0,
          team:0,
          list:0,
      },
      mationInfo:"",
      showOpenList:false,
      SwitchInfo:[],
      showNull:false,

    }
  },
  created(){
    this.projectId = sessionStorage.getItem('projectId')
    console.log(this.projectId)
    this.companyName = sessionStorage.getItem('companyName')
    this.getDate()
    // 获取企业信息
    this.InformationFn()
    
  },
  mounted(){ 
    this.getList()
  },
  methods:{
    InformationFn(){
      currCompanyShowDetailInfo().then(res=>{
        this.mationInfo = res.data
      })
    },
    getDate(){
      const _this = this
       _this.dataTime = new Date()
      setInterval(()=>{
        _this.dataTime = new Date()
      },1000)
    },
    close(){
      this.visible = false
      this.defaultOption.step = 0.5
    },
    close2(){
      this.visible2 = false
      this.defaultOption.step = 0.5
    },
    popUp(item,index){
      console.log(item,index)
      
      if(index==1){
        if(item.length > 0){
          this.defaultOption.step = 0
          this.visible = true
          this.modelObj = item
        }
      }else{
        if(item.length > 0){
          this.defaultOption.step = 0
          this.visible2 = true
          this.modelObj2 = item
        }
      }
    },
    getList(){
      codeLoselectSalaryScreengin({
        projectId:this.projectId
      }).then(res=>{
        this.copyList = cloneDeep(res.data)
        this.dataList = cloneDeep(this.copyList)
        this.start = {
          company:0,
          team:0,
          list:0,
        }
        this.copyList.forEach((item1)=>{
            this.start.company = this.start.company+1
            item1.groupMapList.forEach((item2)=>{
              this.start.team = this.start.team+1
              item2.userInfoList.forEach(()=>{
                 this.start.list = this.start.list+1
              })
            })
        })
        console.log(this.start)
        if(this.start.team > 3){
          if(this.dataList.length < 6){
            this.dataList = this.dataList.concat(this.copyList,this.copyList,this.copyList)
          }
        }else if(this.start.team < 3 && this.start.list > 9){
          if(this.dataList.length < 6){
            this.dataList = this.dataList.concat(this.copyList,this.copyList,this.copyList)
          }
        }
        // if(this.dataList.length < 6){
        //    this.dataList = this.dataList.concat(this.copyList,this.copyList,this.copyList)
        // }
        console.log(this.dataList)
      })
    },
    shopScroll(){
      console.log(123)
      this.shopType = !this.shopType
      if(this.shopType == false){
        this.defaultOption.step = 0
      }else{
        this.defaultOption.step = 0.5
      }

    },
    showOpenListFn(){
        console.log("123")
        screenSwitchInfo().then(res=>{
          if(res.code == 200 && res.data){
            this.SwitchInfo = res.data
            if(this.SwitchInfo.projectScreenPermissionStatus == 0 && this.SwitchInfo.salaryScreenPermissionStatus == 0){
              this.showOpenList = !this.showOpenList
              this.showNull = true
              console.log(1)
            }else{
              this.showOpenList = !this.showOpenList
              this.showNull = false
            }
            // this.SwitchInfo.projectScreenPermissionStatus = 1
            // this.SwitchInfo.salaryScreenPermissionStatus = 1
          }
        }).catch(err=>{
          console.log(err)
        })
      },
      open(type){
        console.log(type)
        if(type==1){
          console.log(1)
          this.showOpenList = !this.showOpenList
          this.$router.push({
            path:"/"
          })

        }
        if(type==2){
          console.log(1)
          this.showOpenList = !this.showOpenList
          this.$router.push({
            path:"/managerScreen/groupManager"
          })
        }
        // if(type==2){
        //   this.showOpenList = !this.showOpenList
        //   console.log("环境: ", process.env.NODE_ENV,process.env.NODE_ENVS,process.env.VUE_APP_SERVER)
        //   if(process.env.VUE_APP_SERVER == "https://nllcbdev.lanlingcb.com/llcb"){
        //     console.log("https://nllcbdev.lanlingcb.com/llcb")
        //     window.open("https://nllcbdev.lanlingcb.com/managerScreen", "_blank");
        //   }
        //   if(process.env.VUE_APP_SERVER == "https://nllcbtest.lanlingcb.com/llcb/"){
        //     console.log("https://nllcbtest.lanlingcb.com/llcb/")
        //     window.open("https://nllcbtest.lanlingcb.com/managerScreen", "_blank");
        //   }
        //   if(process.env.VUE_APP_SERVER == "https://prenllcb.kaishuihu.com/llcb"){
        //     console.log("https://prenllcb.kaishuihu.com/llcb")
        //     window.open("https://prenllcb.kaishuihu.com/managerScreen", "_blank");
        //   }
        //   if(process.env.VUE_APP_SERVER == "https://nllcb.kaishuihu.com/llcb"){
        //     console.log("https://nllcb.kaishuihu.com/llcb")
        //       window.open("https://nllcb.kaishuihu.com/managerScreen", "_blank");
        //   }
        // }
        if(type==3){
          this.$router.push({
            path:"/salary"
          })
          this.showOpenList = !this.showOpenList
        }
      },
      closeTab(){
        this.showOpenList = false
      },
  },

}
</script>
<style scoped lang="less">
  .mt{
    margin-top: .2rem;
  }
  .mb{
    margin-bottom: .2rem;
  }
  .bg{
      position: relative;
      max-width: 19.2rem;
      min-height: 10.8rem;
      // background: url("../../assets/screen/bg2.png") no-repeat 0 0 ;
      background-repeat:no-repeat;
      background-size:100% 100%;
      .header{
        height: 1rem;
        width: 100%;
        position: relative;
        .logo-box{
          width: 3.3rem;
          height: .50rem;
          position: absolute;
          top: .45rem;
          left: .34rem;
          display: flex;
          flex-wrap: wrap;
          .logo{
            width: 2.6rem;
            height: .5rem;
            font-size: .2rem;
            line-height: .38rem;
            color: #d0fffd;
          }
           .logo-title{
            width: 2.6rem;
            font-size: 0.16rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: bold;
          }
          .interval{
            // border-right: .03rem solid #236495;
            height: .19rem;
            margin-top: .08rem;
            margin-left: .3rem;
          }
          .dingwei{
            position: absolute;
            top: 0;
            left: 1.3rem;
          }
          .weather{
            margin-right: .20rem;
            width: 1.50rem;
            height: .50rem;
            text-align: right;
            font-size: .20rem;
            color: #d0fffd;
            .return{
              position: absolute;
              top: -0.2rem;
              width: 1.2rem;
              height: 0.57rem;
              cursor: pointer;
              img{
                width: 100%;
              }
            }
          }
        }
        .title{
          position: absolute;
          width: 3.70rem;
          height: .50rem;
          left: 50%;
          margin-left:-1.85rem;
          margin-top: .32rem;
          font-size: 0.25rem;
          line-height: 0.9rem;
          color: #d0fffd;
          font-weight: bold;
          .title-img{
            width:100%
          }
        }
        .date-box{
          width: 3.30rem;
          position: absolute;
          top: 0.41rem;
          right: 0.25rem;
          display: flex;
          flex-wrap: wrap;
          font-size: .20rem;
          color: #d0fffd;
          justify-content: space-between;
          .left{
            position: relative;
            text-align:left;
          }
          .span-division{
            margin: 0  .15rem;
            color: #338BCA;

          }
          .time{
            font-size: .18rem;
          }
          .date{
            font-size: .18rem;
          }
          .interval{
            // border-right: .03rem solid #236495;
            height: .19rem;
            margin: 0 .1rem;
            margin-top: .2rem;
          }
          .logout{
            position: relative;
            top: .1rem;
            right: .1rem;
            width: 0.88rem;
            height:.37rem;
            cursor: pointer;
            img {
              width: 100%;
            }
          }
        }
      }
  }
  .content{
    position: absolute;
    // background: red;
    width: 18.16rem;
    top:1.86rem;
    height: 7.88rem;
    left: 50%;
    margin-left: -9.04rem;
    overflow-x: scroll;
    // overflow: hidden;
    padding: 0 0.12rem;
    box-sizing: border-box;
  }
  .content::-webkit-scrollbar {
    display: none;
  }
  .mb-20{
    margin-bottom: .2rem;
  }

// .swiper-slide{
// 	height: auto!important;
// }

 
  .model-shadow2{
    position: absolute;
    top: 0;
    left: 0;
    width: 19.20rem;
    min-height: 10.80rem;
    background:rgba(1,14,34,.7);
    z-index: 1000;
    .model{
      position:absolute;
      top: 25%;
      left: 50%;
      margin-left: -3.21rem;
      width: 6.42rem;
      height: 3.00rem;
      background:rgba(1,18,39,0.83);
      border: 1px solid #00adff;
      border-radius: .05rem;
      box-shadow: 0rem 0rem .12rem 0 rgb(0 173 255 / 60%);
      .model-box{
        .model-scroll{
          height: 1.8rem;
          overflow-y: scroll;
        }
      .model-scroll::-webkit-scrollbar {
          display: none;
        }
      }

      // .model-box::-webkit-scrollbar {
      //     display: none;
      // }
      .title{
        color: #d0fffd;
        font-size: .16rem;
        margin-top: .20rem;
      }
      .close{
        position: absolute;
        top: 0.1rem;
        right: 0.16rem;
        font-size: 0.16rem;
        color:#00adff ;
      }
      .model-content{
        margin-top: .30rem;
          .table-title{
            color:#58d1ff;
            display: flex;
            justify-content: space-between;
            height: .50rem;
            line-height: .50rem;
            border-bottom: 1px solid #2485c4;
            border-top: 1px solid #2485c4;
            box-shadow: 0.0rem 0.0rem 0.12rem 0px rgb(0 173 255 / 60%);;
            .table-single{
              width: 2.14rem;
              height: .5rem;
              font-size: .16rem;
              
            }
            .table-multiple{
              width: 5rem;
              height: .50rem;
              font-size: .16rem;
            }
            .yuanyin{
            }
          }
        .table-td{
          color:#d0fffd;
          display: flex;
          justify-content: space-between;
          // height: .50rem;
          line-height: .50rem;
          border-bottom: 1px solid #2485c4;
          box-shadow: 0.0rem 0.0rem 0.12rem 0px rgb(0 173 255 / 60%);
          .table-single{
            width: 2.14rem;
            // height: .50rem;
            font-size: .16rem;
          }
          .table-multiple{
            width: 5rem;
            // height: .50rem;
            line-height: .5rem;
            font-size: .16rem;
            overflow-x:scroll;
          }
          .table-multiple::-webkit-scrollbar {
          display: none;
          }
        }
      }
    }
  } 
    
  .rb{
      border-right: 1px solid #2485c4;
      box-shadow: 0.0rem 0.0rem 0.12rem 0px rgb(0 173 255 / 60%);;
    }
  .hand{
    cursor:pointer;
  }
.tableScroll{
  overflow: hidden;
  height:4.96rem;
  .noImg{
    width: 2.26rem !important;
    height: 2.37rem !important;
    margin-top: 3rem;
  }
}

  .marquee_top {
    transition: all 1.9s  ease-out;
    margin-top: -300px;
  }
  .item-class{
    font-size: .16rem;
  }
  .no-top-img{
    margin-top: 3rem;
  }

  .tab-project{
    position: relative;
    margin-right: 0.4rem;
  }
  .tab-list{
    position:absolute;
    top: 0.5rem;
    left: -1.5rem;
    width: 2.4rem;
    z-index: 888;
    min-height: 0.8rem;
    background: #08436d;
    border: 1px solid #00adff;
    padding: 0.08rem;
    box-sizing: border-box;
  }
  .select-tab{
    font-size: 0.12rem;
    border-bottom: 1px solid #00adff;
    height: 0.25rem;
    margin-top: 0.12rem;
  }
  .cursor-pointer{
    cursor:pointer;
  }

  .more{
    width: 0.55rem;
    height:.35rem;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .model-shadow{
      position: absolute;
      top: 0;
      left: 0;
      width: 19.20rem;
      min-height: 10.80rem;
      background:rgba(1,14,34,.7);
      z-index: 1000;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      .models{
        position:absolute;
        top: 27%;
        left: 50%;
        margin-left: -4.00rem;
        margin-top: -2.00rem;
        width: 8.00rem;
        height: 7.00rem;
        background: url("../../assets/screen/modelBox.png") no-repeat center center;
        background-size: 100%;
 
        .models-box{
          display: flex;
          justify-content: space-around;
          align-items: center;
          padding-top: 1.4rem;
          box-sizing: border-box;
          .modelcontent{
            width: 2.4rem;
            height: 4rem;
            background: url("../../assets/screen/modelPro.png") no-repeat center center;
            background-size: 100%;
            padding-top: 1.8rem;
            box-sizing: border-box;
            .modeltext{
              font-size: 0.2rem;
              color: #D0FFFD;
              padding: 0.1rem;
              box-sizing: border-box;
            }
          }
          .close{
              position: absolute;
              width: 0.5rem;
              height: 0.5rem;
              bottom: 0;
              left: 50%;
              margin-left: -0.25rem;
              z-index: 1000;
              background: url("../../assets/screen/close.png") no-repeat center center;
              background-size: 100%;
            }
        }
        .zwqx{
          font-size: 0.22rem;
          margin-top: 2rem;
          color:#00adff;
        }
      }
  
      .model{
        position:absolute;
        top: 25%;
        left: 50%;
        margin-left: -2.00rem;
        width: 4.00rem;
        height: 3.00rem;
        background:rgba(1,18,39,.9);
        border: .02rem solid #00adff;
        border-radius: .05rem;
        box-shadow: 0 0 .12rem rgb(0 173 255 / 75%);
        .title{
          color: #58d2ff;
          font-size: .16rem;
          margin-top: .20rem;
        }
        .content{
          margin-top: .30rem;
          .select-box{
            margin-top: .20rem;
            height:.48rem;
            .txt-style{
              color: #58d2ff;
              font-size: .14rem;
            }
            .select{
              width: 1.40rem;
              height: .30rem;
              background:rgba(1,18,39,.8);
              color:#00adff;
              border: .01rem solid #00adff;
              border-radius: .05rem;
              box-shadow: 0 0 .06rem rgb(0 173 255 / 75%);
              outline: none
            }
            .check_company{
              margin-left:.18rem;
              margin-top: .10rem;
              color:#58d2ff;
              font-size: .12rem;
            }
          }
        }
        .footer{
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: .50rem;
          display: flex;
          justify-content: flex-end;
          .submit{
            margin-right: .30rem;
            font-size: .14rem;
          }
          .close{
            margin-right: .10rem;
            font-size: .14rem;
          }
          .btn{
            width: .50rem;
            height: .30rem;
            line-height: .30rem;
            color: #58d2ff;
            border: .01rem solid #00adff;
            border-radius: .05rem;
            cursor:pointer;
          }
        }
      }
    } 
</style>
