<template>
	<div class="table">
      <div class="corporateName">{{dataInfo.companyName}}</div>
      <div class="table-border mt-30" v-for="(item1,index1) in dataInfo.groupMapList" :key="index1">
        <div class="table-header">
          <div class="corporate-name">
            <div class="corporate-team ml-20">{{item1.groupName}} / 组长：{{item1.leaderName}} </div>
          </div>
        </div>
        <div class="table-content" >
          <div class="table-title border" >
            <div class="table-single rb ">
              工种
            </div>
            <div class="table-single rb">
              单位
            </div>
            <div class="table-single rb">
              薪资上限标准
            </div>
            <div class="table-multiple rb">
              正常薪资
            </div>
            <div class="table-multiple rb">
              超标实时薪资
            </div>
            <div class="table-single">
              备注
            </div>
          </div>
          <div 
            class="table-td border" 
            :style="{background:(index2%2)===0?color1:''}" 
            v-for="(item2,index2) in (item1.userInfoList)" 
            :key="index2" >
              <div class="table-single rb ">
                {{item2.varietiesName}}
              </div>
              <div class="table-single rb">
                {{item2.unitName}}
              </div>
              <div class="table-single rb green">
                {{item2.salaryToplimitName}}
              </div>
              <!-- 正常薪资  exceedSalaryList-->
                <div class="table-multiple rb ">
                <div 
                  v-if="true" 
                  :class="['flex',
                  item2.noramlSalaryList.cellNum=='5' ? 'col-multiple-5' : '',
                  item2.noramlSalaryList.cellNum=='4' ? 'col-multiple-4' : '',
                  item2.noramlSalaryList.cellNum=='3' ? 'col-multiple-3' : '',
                  item2.noramlSalaryList.cellNum=='2' ? 'col-multiple-2' : '',
                  item2.noramlSalaryList.cellNum=='1' ? 'col-multiple-1' : '',
                  ]">
                  <div 
                  :class="['col-box font-size-12 cursor-pointer',item2.noramlSalaryList.salarySectionMapList.length==(index3+1)?'':'rb']" 
                  @click="popUp(item2.noramlSalaryList.salarySectionMapList[index3].salaryUserMapList,1)"
                  v-for="(item3,index3) in item2.noramlSalaryList.cellNum" 
                  :key="index3">
                  {{item2.noramlSalaryList.salarySectionMapList[index3].salarySectionName}}
                  ({{item2.noramlSalaryList.salarySectionMapList[index3].userNum}}人)
                  </div>
                </div>
              </div>
                <!-- 异常薪资  noramlSalaryList -->
              <div class="table-multiple rb red">
                <div 
                  v-if="true" 
                  :class="['flex font-size-12',
                  item2.exceedSalaryList.cellNum=='5' ? 'col-multiple-5' : '',
                  item2.exceedSalaryList.cellNum=='4' ? 'col-multiple-4' : '',
                  item2.exceedSalaryList.cellNum=='3' ? 'col-multiple-3' : '',
                  item2.exceedSalaryList.cellNum=='2' ? 'col-multiple-2' : '',
                  item2.exceedSalaryList.cellNum=='1' ? 'col-multiple-1' : '',
                  ]">
                    <div 
                    :class="['col-box cursor-pointer',item2.exceedSalaryList.salarySectionMapList.length==(index3+1)?'':'rb']" 
                    @click="popUp(item2.exceedSalaryList.salarySectionMapList[index3].salaryUserMapList,1)"
                    v-for="(item3,index3) in item2.exceedSalaryList.cellNum" 
                    :key="index3">
                        {{item2.exceedSalaryList.salarySectionMapList[index3].salarySectionName}}
                        ({{item2.exceedSalaryList.salarySectionMapList[index3].userNum}}人)
                    </div>
                </div>
              </div>
              <div class="table-single cursor-pointer" @click="popUp(item2.memoMapList,2)">
                <span class="clamp">
                  {{item2.memo}}
                </span>
              </div>
          </div>
        </div>
      </div>
	</div>
</template>
<script>
export default ({
	props:{
		dataInfo:{}
	},
	data() {
		return {
			current:4,
			currentList:[11,22,33,44,55],
			color1:"#082948",
		}
	},
	methods:{
		popUp(item,index){
	console.log(item,index)
      this.$emit("tableShow",item,index)
		},
	},
})
</script>
<style scoped lang="less">
	.table{
		.table-border{
			border: 1px solid rgba(36,133,196,0.6);
			box-shadow: 0.0rem 0.0rem 0.12rem 0px rgba(255,255,255,0.2);
			.corporate-name{
				display: flex;
				justify-content: space-between;
				height: .46rem;
				line-height: .46rem;
				.corporate-title{
					font-size: .16rem;
					margin-left: 0.15rem;
					color: #dce8fb;
				}
				.corporate-team{
					font-size: .16rem;
					margin-right: 0.15rem;
					color: #dce8fb;
				}
			}
		}
		.table-content{
			.rb{
				border-right: 1px solid rgba(36,133,196,0.6);
				box-shadow: 0.0rem 0.0rem 0.12rem 0px rgba(255,255,255,0.2);
			}
			.table-title{
				color:#58d1ff;
				display: flex;
				justify-content: space-between;
				height: .60rem;
				line-height: .60rem;
				border-top: 1px solid rgba(36,133,196,0.6);
				box-shadow: 0.0rem 0.0rem 0.12rem 0px rgba(255,255,255,0.2);
				.table-single{
					width: 1.4rem;
					height: .6rem;
					font-size: .16rem;
					
				}
				.table-multiple{
					width: 6.1rem;
					height: .6rem;
					font-size: .16rem;
				}
			}
			.table-td{
				color:#d0fffd;
				display: flex;
				justify-content: space-between;
				height: .60rem;
				line-height: .60rem;
				border-top: 1px solid rgba(36,133,196,0.6);
				box-shadow: 0.0rem 0.0rem 0.12rem 0px rgba(255,255,255,0.2);
				.table-single{
					width: 1.4rem;
					height: .6rem;
					font-size: .16rem;
				}
				.table-multiple{
					width: 6.1rem;
					height: .6rem;
					font-size: .16rem;
					display:-webkit-box;
					overflow:hidden;
					-webkit-box-orient:vertical;
					-webkit-line-clamp:2;  //显示两行
					.col-multiple-5{
						// height: .6rem;
						// line-height: 0.3rem;

					}
				}
				.green{
					color: #01f18e;
				}
				.red{
					color: red;
				}
			}
		}	
	}
	.col-5{
		box-sizing: border-box;
		.col-box{
			width: 30%;
		}
	}
	.col-multiple-5{
		width: 100%;
		box-sizing: border-box;
		line-height: 0.6rem;
		height:0.6rem;
		.col-box{
			width: 20%;
		}
	}
	.col-multiple-4{
		width: 100%;
		box-sizing: border-box;
		
		.col-box{
			width: 25%;
		}
	}
	.col-multiple-3{
		width: 100%;
		box-sizing: border-box;
		.col-box{
			width: 33.33%;
		}
	}
	.col-multiple-2{
		width: 100%;
		box-sizing: border-box;
		.col-box{
			width: 50%;
		}
	}
	.col-multiple-1{
		width: 100%;
		box-sizing: border-box;
		.col-box{
			width: 100%;
		}
	}
	.flex{
		display: flex;
		justify-content: space-between;
	}
	.clamp{
		display:-webkit-box;
		overflow:hidden;
		-webkit-box-orient:vertical;
		-webkit-line-clamp:1;  //显示两行
	}
  .corporateName{
    font-size: 0.2rem;
    text-align: left;
    // height: 0.40rem;
    // line-height: 0.4rem;
    color: #dce8fb;
    margin-left: .2rem;
  }
  .ml-20{
    margin-left: 0.2rem;
  }
  .mt-30{
    margin-top: 0.2rem;
  }
  .font-size-12{
	font-size: 0.12rem;
  }
  .cursor-pointer{
	cursor:pointer;
  }
</style>