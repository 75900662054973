import { request,METHOD } from '@/utils/request.js'
const apiList = {
    selectSalaryScreen: '/business/salary/selectSalaryScreen',
    currCompanyShowDetailInfo: '/business/companyScreenConfig/currCompanyShowDetailInfo',
    screenSwitchInfo:'/business/companyScreenConfig/screenSwitchInfo', //大屏切换
    
}


export function codeLoselectSalaryScreengin(parameter) {
  return request({
    url: apiList.selectSalaryScreen,
    method: METHOD.POST,
    data: parameter,
    isformData:true
  })
}

// 查询当前登录企业大屏配置展示详情信息  /business/companyScreenConfig/currCompanyShowDetailInfo


export function currCompanyShowDetailInfo(parameter) {
  return request({
    url: apiList.currCompanyShowDetailInfo,
    method: METHOD.POST,
    data: parameter,
    isformData:true
  })
}


export function screenSwitchInfo(parameter) {
  return request({
    url: apiList.screenSwitchInfo,
    method: METHOD.POST,
    data: parameter,
    isformData:false
  })
}